import cn from 'classnames';
import type { Duration } from 'dayjs/plugin/duration';
import { useMemo } from 'react';

import { DURATION_FORMAT } from '@webapp/common/lib/date';
import { QuestionTimer } from '@webapp/ui/lib/icons';

import { CssPageBlock } from '../survey-custom';

import css from './time-left.css';

export const TimeLeft: FC<{
    value: Duration;
    className?: string;
    color?: string;
}> = ({ className, color, value }) => {
    const time = value.format(DURATION_FORMAT);
    const style = useMemo(() => ({ color }), [color]);
    return (
        <span className={cn(CssPageBlock.QUESTION_TIMER, css.timer, className)} style={style}>
            <QuestionTimer />
            <span>{time}</span>
        </span>
    );
};
