/* pack1 */
import cross from './cross.svg';
import plus from './plus.svg';
import angry1 from './smiles/pack1/angry.svg';
import app1 from './smiles/pack1/approval.svg';
import bad1 from './smiles/pack1/bad.svg';
import good1 from './smiles/pack1/good.svg';
import normal1 from './smiles/pack1/normal.svg';
import not_app1 from './smiles/pack1/not-approval.svg';
import perfect1 from './smiles/pack1/perfect.svg';
/* pack2 */
import angry2 from './smiles/pack2/angry-pack2.svg';
import app2 from './smiles/pack2/approval-pack2.svg';
import bad2 from './smiles/pack2/bad-pack2.svg';
import good2 from './smiles/pack2/good-pack2.svg';
import normal2 from './smiles/pack2/normal-pack2.svg';
import not_app2 from './smiles/pack2/not-approval-pack2.svg';
import perfect2 from './smiles/pack2/perfect-pack2.svg';
/* pack3 */
import angry3 from './smiles/pack3/angry.svg';
import app3 from './smiles/pack3/approval.svg';
import bad3 from './smiles/pack3/bad.svg';
import good3 from './smiles/pack3/good.svg';
import normal3 from './smiles/pack3/normal.svg';
import not_app3 from './smiles/pack3/not-approval.svg';
import perfect3 from './smiles/pack3/perfect.svg';
/* pack4 */
/* pack5 */
import angry5 from './smiles/pack5/angry-pack5.svg';
import app5 from './smiles/pack5/approval-pack5.svg';
import bad5 from './smiles/pack5/bad-pack5.svg';
import good5 from './smiles/pack5/good-pack5.svg';
import normal5 from './smiles/pack5/normal-pack5.svg';
import not_app5 from './smiles/pack5/not-approval-pack5.svg';
import perfect5 from './smiles/pack5/perfect-pack5.svg';
/* pack6 */
import angry6 from './smiles/pack6/angry-pack6.svg';
import app6 from './smiles/pack6/approval-pack6.svg';
import bad6 from './smiles/pack6/bad-pack6.svg';
import good6 from './smiles/pack6/good-pack6.svg';
import normal6 from './smiles/pack6/normal-pack6.svg';
import not_app6 from './smiles/pack6/not-approval-pack6.svg';
import perfect6 from './smiles/pack6/perfect-pack6.svg';

export enum SmilePackName {
    PACK1 = 'pack1',
    PACK2 = 'pack2',
    PACK3 = 'pack3',
    PACK4 = 'pack4',
    PACK5 = 'pack5',
    PACK6 = 'pack6'
}

export const DEFAULT_SMILE_PACK = SmilePackName.PACK5;
export enum SmileName {
    ANGRY = 'angry',
    APPROVAL = 'approval',
    BAD = 'bad',
    GOOD = 'good',
    NORMAL = 'normal',
    NOT_APPROVAL = 'not-approval',
    PERFECT = 'perfect',
    PLUS = 'plus',
    CROSS = 'cross'
}

export const SmilePacks = {
    [SmilePackName.PACK1]: {
        [SmileName.ANGRY]: angry1,
        [SmileName.APPROVAL]: app1,
        [SmileName.BAD]: bad1,
        [SmileName.GOOD]: good1,
        [SmileName.NORMAL]: normal1,
        [SmileName.NOT_APPROVAL]: not_app1,
        [SmileName.PERFECT]: perfect1
    },
    [SmilePackName.PACK2]: {
        [SmileName.ANGRY]: angry2,
        [SmileName.APPROVAL]: app2,
        [SmileName.BAD]: bad2,
        [SmileName.GOOD]: good2,
        [SmileName.NORMAL]: normal2,
        [SmileName.NOT_APPROVAL]: not_app2,
        [SmileName.PERFECT]: perfect2
    },
    [SmilePackName.PACK3]: {
        [SmileName.ANGRY]: angry3,
        [SmileName.APPROVAL]: app3,
        [SmileName.BAD]: bad3,
        [SmileName.GOOD]: good3,
        [SmileName.NORMAL]: normal3,
        [SmileName.NOT_APPROVAL]: not_app3,
        [SmileName.PERFECT]: perfect3
    },
    [SmilePackName.PACK4]: {
        [SmileName.ANGRY]: cross,
        [SmileName.APPROVAL]: cross,
        [SmileName.BAD]: cross,
        [SmileName.GOOD]: cross,
        [SmileName.NORMAL]: cross,
        [SmileName.NOT_APPROVAL]: cross,
        [SmileName.PERFECT]: cross,
        [SmileName.PLUS]: plus,
        [SmileName.CROSS]: cross
    },
    [SmilePackName.PACK5]: {
        [SmileName.ANGRY]: angry5,
        [SmileName.APPROVAL]: app5,
        [SmileName.BAD]: bad5,
        [SmileName.GOOD]: good5,
        [SmileName.NORMAL]: normal5,
        [SmileName.NOT_APPROVAL]: not_app5,
        [SmileName.PERFECT]: perfect5
    },
    [SmilePackName.PACK6]: {
        [SmileName.ANGRY]: angry6,
        [SmileName.APPROVAL]: app6,
        [SmileName.BAD]: bad6,
        [SmileName.GOOD]: good6,
        [SmileName.NORMAL]: normal6,
        [SmileName.NOT_APPROVAL]: not_app6,
        [SmileName.PERFECT]: perfect6
    }
};
