import cn from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';

import { calcNpsGradient } from '@webapp/common/lib/color';

import css from './gradient-numbers.css';

type DigitSize = 'small' | 'normal';

export const ColoredDigit: FC<{
    color: string;
    label: string | number;
    onClick?: () => void;
    selected?: boolean;
    size?: DigitSize;
    className?: string;
}> = ({ color, label, onClick, selected, size, className }) => {
    const style = useMemo(() => ({ color }), [color]);

    return (
        <div
            style={style}
            className={cn(
                css.item,
                selected && css.itemSelected,
                onClick && css.itemClickable,
                size === 'small' && css.small,
                className,
                className && selected && `${className}_active`
            )}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

export const GradientNumbers: FC<{
    colors: Array<string>;
    itemsCount?: number;
    startsWith?: number;
    onClick?: (value: number) => void;
    selectedValue?: number;
    disabled?: boolean;
    size?: DigitSize;
}> = ({ colors, disabled, itemsCount = 11, onClick, selectedValue, size = 'normal', startsWith = 0 }) => {
    const gradient = useMemo(() => calcNpsGradient(colors, itemsCount), [colors, itemsCount]);

    return (
        <div className={cn(css.gradientNumbers, disabled && css.disabled, 'gradientNumbers')}>
            {gradient.map((color, index) => {
                const currentLabel = startsWith + index;
                return (
                    <ColoredDigit
                        color={color}
                        key={index}
                        label={currentLabel}
                        selected={selectedValue === currentLabel}
                        size={size}
                        onClick={onClick ? () => onClick(currentLabel) : undefined}
                        className='gradientNumber'
                    />
                );
            })}
        </div>
    );
};
