import cn from 'classnames';

import type { SmileName, SmilePackName } from './smiles';
import { SvgSmile } from './svg-smile';

import css from './smile-image.css';

export const SmileImage: FC<{
    pack: SmilePackName;
    name: SmileName;
    size?: number;
    onClick?: () => void;
}> = ({ name, onClick, pack, size = 1 }) => {
    return (
        <div
            className={cn(css.smile, css[pack], {
                [css.small]: size === 1,
                [css.large]: size === 2
            })}
            onClick={onClick}
        >
            <SvgSmile name={name} pack={pack} />
        </div>
    );
};
