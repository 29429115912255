import { useMemo } from 'react';
import tinycolor from 'tinycolor2';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { ColorScheme, createMarginStyle, createTextStyle, getColor } from '@webapp/common/lib/ui';
import type { InfoDesignModel } from '@webapp/common/resources/entities/info';

import type { CustomStylesConfig } from './custom-styles';

export const useViewCustomStylesNew = ({
    brand: { backgroundColor },
    fonts: {
        buttonColor,
        buttonTextColor,
        buttonWidth,
        buttonHeight,
        buttonBorderRadius,
        elementColor,
        fieldBorderColor: fieldBorderColorOriginal,
        questionTextColor,
        replyTextBold,
        replyTextColor,
        replyTextItalic,
        replyTextSize,
        replyTextThrough,
        replyTextUnderline,
        titleBold,
        titleColor,
        titleItalic,
        titleSize,
        titleThrough,
        titleUnderline
    },
    view: { gap, lineHeight, paddingBottom, paddingLeft, paddingRight, paddingTop, questionBlockPosition }
}: InfoDesignModel): CustomStylesConfig => {
    const fieldBorderColorRgba = tinycolor(fieldBorderColorOriginal);
    const fieldBorderColor = fieldBorderColorRgba.toRgbString();
    const alfa10Color = fieldBorderColorRgba.setAlpha(0.1).toRgbString();
    const alfa30Color = fieldBorderColorRgba.setAlpha(0.3).toRgbString();
    const halfColor = fieldBorderColorRgba.setAlpha(0.5).toRgbString();
    const markColor = buttonTextColor === ColorScheme.LIGHT ? getColor('white') : getColor('black');
    const textStyle = createTextStyle({
        underline: replyTextUnderline,
        through: replyTextThrough,
        bold: replyTextBold,
        italic: replyTextItalic,
        color: replyTextColor,
        size: replyTextSize
    });
    const isMobile = useMobile();

    return useMemo(
        () => ({
            fieldBorderColor,
            lineHeight,
            contentAlign: questionBlockPosition,
            gap,
            survey: createMarginStyle(paddingTop, paddingLeft, paddingBottom, paddingRight),
            datePicker: {
                root: {
                    '--borderColor': fieldBorderColor
                } as any,
                arrow: {
                    color: fieldBorderColor
                }
            },
            checkbox: {
                root: {
                    border: `1px solid ${fieldBorderColor}`,
                    backgroundColor: 'transparent'
                },
                checked: {
                    backgroundColor: fieldBorderColor,
                    color: markColor
                },
                label: {
                    maxWidth: '80vw',
                    ...textStyle
                }
            },
            question: {
                title: createTextStyle({
                    underline: titleUnderline,
                    through: titleThrough,
                    bold: titleBold,
                    italic: titleItalic,
                    color: titleColor,
                    size: titleSize
                }),
                text: {
                    ...textStyle,
                    color: questionTextColor
                }
            },
            text: textStyle,
            textField: {
                size: replyTextSize,
                backgroundColor: alfa10Color,
                color: replyTextColor,
                minWidth: 120,
                '--c-border-focus': fieldBorderColor,
                borderColor: alfa30Color
            } as any,
            select: {
                arrow: {
                    color: fieldBorderColor
                },
                dropdownBg: {
                    backgroundColor: halfColor
                },
                activeItem: { backgroundColor: halfColor },
                item: {
                    borderColor: halfColor,
                    backgroundColor: alfa10Color,
                    minWidth: 100,
                    height: 'unset',
                    whiteSpace: 'pre-wrap',
                    borderTop: `1px solid ${halfColor}`,
                    color: getColor('grey3')
                },
                label: {
                    width: 'auto',
                    minWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: replyTextColor
                },
                root: {
                    backgroundColor: alfa10Color,
                    borderColor: alfa30Color,
                    '--c-border-focus': fieldBorderColor,
                    width: isMobile ? '100%' : 240,
                    maxWidth: isMobile ? undefined : 240,
                    ...textStyle
                }
            },
            star: {
                root: {
                    stroke: alfa10Color,
                    fill: alfa10Color
                }
            },
            icon: {
                root: {
                    fill: 'transparent',
                    stroke: fieldBorderColor
                }
            },
            imageSlider: {
                root: {
                    color: elementColor
                }
            },
            selectImage: {
                resizer: {
                    color: fieldBorderColor
                }
            },
            button: {
                root: {
                    backgroundColor: buttonColor,
                    color: markColor,
                    width: buttonWidth,
                    height: buttonHeight,
                    borderRadius: buttonBorderRadius
                }
            },
            matrix: {
                fixedHeader: {
                    backgroundColor: backgroundColor
                }
            }
        }),
        [
            fieldBorderColor,
            lineHeight,
            questionBlockPosition,
            gap,
            paddingTop,
            paddingLeft,
            paddingBottom,
            paddingRight,
            markColor,
            textStyle,
            titleUnderline,
            titleThrough,
            titleBold,
            titleItalic,
            titleColor,
            titleSize,
            questionTextColor,
            replyTextSize,
            alfa10Color,
            replyTextColor,
            alfa30Color,
            halfColor,
            isMobile,
            elementColor,
            buttonColor,
            backgroundColor
        ]
    );
};
