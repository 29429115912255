import { useContext, useMemo } from 'react';

import { getColor } from '@webapp/common/lib/ui';

import { CustomStylesCtx } from '../custom-styles';

import { DEFAULT_SMILE_PACK, SmilePackName, SmilePacks } from './smiles';
import type { SmileName } from './smiles';

export const SvgSmile: FC<{ pack: SmilePackName; name: SmileName }> = ({ name, pack }) => {
    const { icon } = useContext(CustomStylesCtx);

    const Smile = SmilePacks[pack]?.[name] || SmilePacks[DEFAULT_SMILE_PACK]?.[name];
    const style = useMemo(
        () =>
            pack === SmilePackName.PACK1 || pack === SmilePackName.PACK6
                ? {
                      color: icon.root?.stroke || getColor('blue2'),
                      '--c-settings-fill': icon.root?.fill || getColor('white')
                  }
                : {},
        [icon.root?.fill, icon.root?.stroke, pack]
    );

    return <Smile style={style} />;
};
