import { useState } from 'react';

import { API_URL } from '@webapp/common/conf';
import { HelpText } from '@webapp/ui/lib/help-text';
import { Zoom } from '@webapp/ui/lib/icons';
import { ImageSlider } from '@webapp/ui/lib/image-slider';

import css from './additional-image.css';

export const AdditionalImage: FC<{ additionalImage: string; additionalText: string; hideZoom?: boolean }> = ({
    additionalImage,
    additionalText,
    hideZoom
}) => {
    const [showSlider, setShowSlider] = useState(false);
    const imageUrl = `${API_URL}${additionalImage}`;

    return (
        <>
            <div>
                <HelpText className={css.helpText} value={additionalText} />
                <div className={css.additionalImageWrap}>
                    <img src={imageUrl} alt='additionalImage' />
                    {!hideZoom && (
                        <Zoom
                            className={css.zoomIcon}
                            onClick={() => {
                                setShowSlider(true);
                            }}
                        />
                    )}
                </div>
            </div>
            {showSlider && (
                <ImageSlider
                    slides={[{ id: 1, img: { url: imageUrl } }]}
                    onClose={() => {
                        setShowSlider(false);
                    }}
                />
            )}
        </>
    );
};
