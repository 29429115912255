import _toArray from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toArray";
import _slicedToArray from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import { useImmer } from 'use-immer';
import { useWindowSize } from '@webapp/common/hooks/use-window-size';
import { calcHeight, getChildrenBoxes } from "./lib";
var DBG = false;
export var useMatrixParams = function useMatrixParams(answers, headerRef, rowsRef) {
  var _useImmer = useImmer('auto'),
      _useImmer2 = _slicedToArray(_useImmer, 2),
      headerHeight = _useImmer2[0],
      setHeaderHeight = _useImmer2[1];

  var _useImmer3 = useImmer(false),
      _useImmer4 = _slicedToArray(_useImmer3, 2),
      ready = _useImmer4[0],
      setReady = _useImmer4[1];

  var _useWindowSize = useWindowSize(),
      _useWindowSize2 = _slicedToArray(_useWindowSize, 1),
      windowWidth = _useWindowSize2[0]; // const labelHeights = useMemo(() => Array(answers.length).fill('auto'), [answers.length]);


  var _useImmer5 = useImmer(function () {
    return Array(answers.length).fill('auto');
  }),
      _useImmer6 = _slicedToArray(_useImmer5, 2),
      rowsHeight = _useImmer6[0],
      setRowsHeight = _useImmer6[1];

  var callbacks = useMemo(function () {
    return Array(answers.length).fill(0).reduce(function (acc, _, idx) {
      return acc.push(debounce(500, function (el) {
        if (el) {
          var _el$getBoundingClient = el.getBoundingClientRect(),
              height = _el$getBoundingClient.height;

          setRowsHeight(function (draft) {
            if (draft[idx] !== height) {
              draft[idx] = height;
            }
          });
        } else {
          setRowsHeight(function (draft) {
            if (draft[idx] !== 'auto') {
              draft[idx] = 'auto';
            }
          });
        }
      })), acc;
    }, []);
  }, [answers.length, setRowsHeight]);
  var setLabelRefs = useCallback(function (idx) {
    return callbacks[idx];
  }, [callbacks]);
  var adjust = useMemo(function () {
    return debounce(100, function (header, rows) {
      setReady(false);
      requestAnimationFrame(function () {
        if (header) {
          var childrenBoxes = getChildrenBoxes(header);
          DBG && console.log(header, childrenBoxes);
          var h = calcHeight(childrenBoxes);
          DBG && console.log('header', h);
          setHeaderHeight(h);
        }

        if (rows) {
          var _rowsHeight = [];

          var _iterator = _createForOfIteratorHelper(rows.children),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var r = _step.value;

              var _getChildrenBoxes = getChildrenBoxes(r),
                  _getChildrenBoxes2 = _toArray(_getChildrenBoxes),
                  contentBoxes = _getChildrenBoxes2.slice(1); // skip mobile view row


              var _h = calcHeight(contentBoxes);

              var num = parseFloat(_h);

              _rowsHeight.push(num);

              DBG && console.log("row ".concat(_rowsHeight.length), num);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          setRowsHeight(function (draft) {
            draft.forEach(function (r, idx) {
              if (r === 'auto') {
                return _rowsHeight[idx];
              } else {
                return Math.max(r, _rowsHeight[idx]);
              }
            });
          });
        }

        setReady(true);
        DBG && console.log('ready');
      });
    });
  }, [setHeaderHeight, setReady, setRowsHeight]);
  useEffect(function () {
    var header = headerRef.current;
    var headerResizeObserver = new ResizeObserver(function () {
      adjust(header, rowsRef.current);
    });
    headerResizeObserver.observe(header);
    adjust(header, rowsRef.current);
    return function () {
      headerResizeObserver.unobserve(header);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, windowWidth]);
  return useMemo(function () {
    return {
      ready: ready,
      headerHeight: headerHeight,
      rowsHeight: rowsHeight,
      setLabelRefs: setLabelRefs
    };
  }, [headerHeight, rowsHeight, setLabelRefs, ready]);
};