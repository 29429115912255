import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { nop } from '@webapp/common/lib/utils';
import { ArrowRight } from '@webapp/ui/lib/icons';

import { CloseRedBtn } from '../close-btn';
import { CustomStylesCtx } from '../custom-styles';
import { CssUiComponent } from '../survey-custom';
import type { TextFieldProps } from '../textfield';

import {
    disableScroll,
    enableScroll,
    getLabel,
    getOptionText,
    getValue,
    onTop,
    preventDefault,
    stopEvent
} from './lib';
import type { SelectOption } from './lib';
import { Option, Options } from './options';
import { Overlay } from './overlay';
import { Portal } from './portal';
import { SelectBox } from './select-box';
import { useDownshift } from './use-downshift';
import { usePopper } from './use-popper';

import css from './select.css';

export type TPreset = 'light' | 'default';
export interface SelectOptions<T = SelectOption> {
    id?: string;
    className?: string;
    labelClassName?: string;
    size?: 'small' | 'default';
    value?: PrimitiveValue;
    defaultValue?: string | number;
    options?: Array<T>;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean | string;
    style?: CSSProperties;
    itemStyle?: CSSProperties;
    activeItemStyle?: CSSProperties;
    arrowStyle?: CSSProperties;
    controlClassName?: string;
    searchable?: boolean;
    fullWidth?: boolean;
    maxWidth?: boolean;
    renderLabel?: (label: string) => ReactNode;
    preset?: TPreset;

    onChange?(value: PrimitiveValue, option?: T): void;
}

export const Select: FC<SelectOptions> = memo(
    ({
        className,
        disabled,
        error,
        id,
        labelClassName,
        size,
        fullWidth,
        maxWidth,
        placeholder = 'Выбрать из списка',
        options = [],
        searchable,
        onChange = nop,
        defaultValue,
        value,
        renderLabel,
        preset
    }) => {
        const isMobile = useMobile();
        const [innerValue, setInnerValue] = useState<PrimitiveValue>(null);
        const [selected, setSelected] = useState<SelectOption>(null);
        const [search, setSearch] = useState<string>('');
        const { select, text } = useContext(CustomStylesCtx);
        const [innerOptions, setInnerOptions] = useState<Array<SelectOption>>(options);
        const visibleValue = renderLabel && selected ? renderLabel(getLabel(selected)) : getLabel(selected);

        const customStyles = useMemo(() => {
            if (fullWidth) {
                return {
                    ...(select || {}),
                    root: {
                        ...(select?.root || {}),
                        minWidth: null,
                        maxWidth: null,
                        width: null
                    }
                };
            }

            return select;
        }, [fullWidth, select]);

        const {
            item: { borderColor },
            root
        } = customStyles;
        const withError = !!error;
        const small = size === 'small' || customStyles.size === 'small';
        const empty = !Array.isArray(options) || options.length === 0;
        const rootRef = useRef(null);

        const [referenceRef, setReferenceRef] = useState(null);
        const [popperRef, setPopperRef] = useState(null);
        const { popperOptions, state, styles } = usePopper(referenceRef, popperRef, maxWidth);
        const top = onTop(state?.placement || popperOptions.placement);

        const getFilteredBySearch = useCallback(
            (search): Array<SelectOption> => {
                search = String(search || '').toLocaleLowerCase();

                if (!(searchable && search)) {
                    return options;
                }

                return options.filter((option) => getOptionText(option)?.toLocaleLowerCase()?.includes(search));
            },
            [options, searchable]
        );

        // TODO purge
        const isValueSelected = useCallback((o: SelectOption): boolean => getValue(o) === innerValue, [innerValue]);
        const findSelected = useCallback(
            (v): SelectOption => {
                const o = options.find((o) => getValue(o) === v);
                return typeof o === 'undefined' ? null : o;
            },
            [options]
        );

        const updateSearch = useCallback(
            (v) => {
                setInnerOptions(getFilteredBySearch(v));
                setSearch(v);
            },
            [getFilteredBySearch]
        );

        const { closeMenu, getInputProps, getMenuProps, getToggleButtonProps, isOpen, openMenu } = useDownshift(
            innerOptions,
            selected,
            updateSearch
        );

        const hideDropdown = useCallback((): void => {
            closeMenu();
            enableScroll();
            if (searchable) {
                updateSearch('');
            }
        }, [closeMenu, searchable, updateSearch]);

        const handleToggle = useCallback((): boolean | void => {
            if (empty || (disabled && !isOpen)) {
                return false;
            }
            if (isOpen) {
                disableScroll();
            } else {
                enableScroll();
            }
            if (searchable || !isOpen) {
                openMenu();
            } else {
                hideDropdown();
            }
        }, [empty, disabled, isOpen, searchable, openMenu, hideDropdown]);

        const inputProps = getInputProps({ placeholder }, { suppressRefError: true });
        const menuProps = getMenuProps({}, { suppressRefError: true });

        const createTitle = (): ReactElement | string => {
            let inputValue = isOpen ? search : visibleValue;
            inputValue = inputValue === null ? '' : inputValue;

            if (!searchable || isMobile) {
                return visibleValue === null ? '' : visibleValue;
            }

            // form forces autocomplete disable
            return (
                <form autoComplete='off' onSubmit={preventDefault}>
                    <input
                        className={css.searchInput}
                        placeholder={placeholder}
                        {...inputProps}
                        autoComplete='off'
                        value={inputValue}
                    />
                </form>
            );
        };

        const clear = useCallback(
            (e) => {
                updateSearch('');
                setInnerValue(null);
                setSelected(null);
                onChange(null);
                e.stopPropagation();
            },
            [onChange, updateSearch]
        );

        const createMobileSearch = useCallback((): ReactElement => {
            const props: TextFieldProps = { ...inputProps };
            props.onChange = (_, e) => {
                e.persist();
                inputProps.onChange(e); // TODO fix action logger
            };

            return (
                <form autoComplete='off' className={css.mobileSearchForm} onSubmit={preventDefault}>
                    <ArrowRight className={css.backButton} onClick={closeMenu} />
                    <input
                        className={css.mobileSearchInput}
                        value={props.value ?? ''}
                        onChange={(e) => props.onChange?.(e.target.value, e)}
                        onClick={stopEvent}
                        placeholder={placeholder}
                    />
                    {!!props.value && (
                        <CloseRedBtn
                            className={css.clear}
                            onClick={(e) => {
                                props.onChange?.('', e as any);
                                clear(e);
                            }}
                        />
                    )}
                </form>
            );
        }, [clear, closeMenu, inputProps, placeholder]);

        const handleChange = useCallback(
            (option: SelectOption): void => {
                const value = getValue(option);

                if (innerValue !== value) {
                    setInnerValue(value);
                    setSelected(option);
                    onChange(value, option);
                }

                hideDropdown();
            },
            [hideDropdown, innerValue, onChange]
        );

        const optionsOverlay = useMemo(
            () => (
                <>
                    <Overlay style={customStyles.dropdownBg} onClick={hideDropdown} />
                    <Options
                        active={isOpen}
                        downshiftProps={menuProps}
                        dropdownStyle={{ ...styles.popper, minWidth: styles.popper.width }}
                        error={withError}
                        maxWidth={maxWidth}
                        mobile={isMobile}
                        ref={setPopperRef}
                        searchable={searchable}
                        small={small}
                        style={root}
                        thumbColor={borderColor}
                        title={isMobile && searchable && createMobileSearch()}
                        top={top}
                        preset={preset}
                    >
                        {innerOptions.map((option, idx) => (
                            <Option
                                key={idx}
                                option={option}
                                selected={isValueSelected(option) /* || highlightedIndex t=== idx*/}
                                small={small}
                                top={top}
                                onClick={handleChange}
                                renderLabel={renderLabel}
                            />
                        ))}
                    </Options>
                </>
            ),
            [
                borderColor,
                createMobileSearch,
                customStyles.dropdownBg,
                handleChange,
                hideDropdown,
                innerOptions,
                isMobile,
                isOpen,
                isValueSelected,
                maxWidth,
                menuProps,
                root,
                searchable,
                small,
                styles.popper,
                top,
                withError
            ]
        );

        useEffect(() => {
            setInnerValue((prev) => {
                let next = prev;
                if (typeof value !== 'undefined') next = value;
                if (prev === null && typeof defaultValue !== 'undefined') next = defaultValue;
                return next;
            });
        }, [value, defaultValue]);

        useEffect(() => {
            setInnerOptions((prev) => {
                if (isEqual(prev, options)) return prev;
                return options;
            });
        }, [options]);

        // update selected on outer value change
        useEffect(() => setSelected(findSelected(innerValue)), [findSelected, innerValue]);

        return (
            <div
                ref={rootRef}
                style={text}
                className={cn(
                    CssUiComponent.SELECT,
                    css.select,
                    {
                        [css.small]: small,
                        [css.active]: isOpen,
                        [css.disabled]: disabled,
                        [css.error]: withError,
                        [css.top]: top
                    },
                    className
                )}
            >
                <SelectBox
                    active={isOpen}
                    customStyles={customStyles}
                    disabled={empty}
                    downshiftProps={getToggleButtonProps()}
                    error={withError}
                    id={id}
                    labelClassName={cn(labelClassName, css.label)}
                    ref={setReferenceRef}
                    searchable={searchable}
                    title={createTitle()}
                    top={top}
                    value={innerValue}
                    onClear={clear}
                    onClick={handleToggle}
                    preset={preset}
                    showArrow={options?.length > 1}
                />

                {maxWidth ? (
                    <Portal visible={isOpen}>{optionsOverlay}</Portal>
                ) : (
                    <Portal>{isOpen && optionsOverlay}</Portal>
                )}
            </div>
        );
    }
);
Select.displayName = 'Select';
