import cn from 'classnames';

import { setHtml } from '@webapp/common/lib/ui';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';

import css from './help-text.css';

export const HelpText: FC<{
    value: string;
    className?: string;
}> = ({ className, value }) => {
    const { textStyle } = useTextStyle();
    return value && <div className={cn(css.note, 'wysiwyg', className)} style={textStyle} {...setHtml(value)} />;
};
