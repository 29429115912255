import type { RgbaColor } from 'react-colorful';
import tinycolor from 'tinycolor2';
import tinygradient from 'tinygradient';

import { getColor } from './ui';

export const calcNpsGradient = (colors: Array<string>, itemsCount = 11): Array<string> => {
    let gradient;
    let npsColors = colors;
    const count = itemsCount < 2 ? 2 : itemsCount;

    if (npsColors?.length > count) {
        npsColors = npsColors.slice(0, count);
    }

    if (!Array.isArray(npsColors) || npsColors.length === 0) {
        const color = getColor('blue-nps');
        gradient = tinygradient(color, color);
    } else if (npsColors.length === 1) {
        gradient = tinygradient(npsColors[0], npsColors[0]);
    } else {
        gradient = tinygradient(npsColors);
    }

    return gradient.rgb(count).map((c) => c.toRgbString());
};

export const colorToHex = (v: string | RgbaColor): string => {
    const color = tinycolor(v);
    if (color.isValid()) {
        let hex = color.toHex8();
        hex = hex.endsWith('FF') ? hex.substr(0, 6) : hex;

        return `#${hex}`;
    }
    return null;
};

export const colorToRgba = (v: string | RgbaColor): RgbaColor => {
    const color = tinycolor(v);
    if (color.isValid()) {
        return color.toRgb();
    }
    return null;
};

export const removeTransparency = (color: string): string => {
    const c = tinycolor(color);

    if (c.isValid()) {
        return c.setAlpha(1).toRgbString();
    }

    return color;
};
