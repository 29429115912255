import { generatePath } from 'react-router-dom';

import { history } from '@webapp/account/src/resources/history';

export const PublicResultsRoutes = {
    results: {
        link: '/results/',
        assign: function (): void {
            window.location.assign(this.link);
        }
    },
    common: {
        link: '/results/common',
        go: function (): void {
            history.push(this.link + window.location.search);
        }
    },
    single: {
        link: '/results/single',
        go: function (): void {
            history.push(this.link + window.location.search);
        }
    },
    answer: {
        link: '/results/single/:answer_id',
        create: function (id: number | string): string {
            return generatePath(this.link, { answer_id: id }) + window.location.search;
        }
    }
};

export const singleRoutes = [PublicResultsRoutes.answer.link, PublicResultsRoutes.single.link];
