import cloneDeep from 'lodash/cloneDeep';

import { DateTimeType, TimeFormat } from '@webapp/common/lib/date';
import { BoolAnswerText, getColor, strTemplate } from '@webapp/common/lib/ui';
import { AnswerType, DropdownType, QuestionType, TYPES_NAMES } from '@webapp/common/resources/survey';
import { DEFAULT_SMILE_PACK } from '@webapp/ui/lib/smile-image/smiles';

import type { Condition } from 'resources/logic/lib';
import {
    DateFormat,
    FileUploadType,
    initialCol,
    initialQuestion,
    initialRow,
    ResidenceType,
    smileTypes
} from 'resources/questions/questions';
import type { QuestionCol, QuestionParams, QuestionRow } from 'resources/questions/questions';

export interface FillerQuestion {
    name: string;
    type: QuestionType;
    rows: Array<QuestionRow>;
    cols: Array<QuestionCol>;
    items?: AnyObject;
    params: QuestionParams;
    conditions: Array<Condition>;
}

export const PLACEHOLDER = {
    // TODO split question and ui
    COLUMN: strTemplate`Столбец ${0}`,
    DISQUAL: 'Текст дисквалификации',
    EMAIL: 'user@mail.com',
    EMPTY: '',
    EXCEPTION: 'Исключение',
    FINISH: 'Текст завершения',
    IDX: strTemplate`${0}`,
    LINK_CODE: 'Код ссылки',
    QUESTION_SUB_QUESTION: 'Подвопрос',
    QUESTION_BAD: 'Плохо',
    QUESTION_GOOD: 'Отлично',
    QUESTION_TEXT: 'Текст вопроса',
    CANT_RATE: 'Не могу оценить',
    RANK: 'балл',
    SURVEY: 'Исследование',
    RGB_NUM: '123',
    RGBA_HEX: '#ffffffff',
    ROW: strTemplate`Строка ${0}`,
    SEARCH: 'Поиск',
    SELF: 'Ответ респондента',
    SURVEY_SEARCH: 'Поиск (введите название или ссылку)',
    TEXT: 'Текст ответа',
    TITLE: 'Название',
    UNIT: 'единица измерения',
    USER_NAME: 'Имя пользователя',
    USER_NIK: 'Ник в ЛК',
    VARIANT: strTemplate`Вариант ${0}`,
    WEB_LINK: 'Ссылка на веб-сайт',
    WEB_SITE_URL_EXAMPLE: 'www.testograf.ru',
    VARIABLE_NAME: 'Название параметра',
    VARIABLE: 'Параметр'
};

export const ROW_CITY = {
    value: ResidenceType.CITY,
    order: 3
};
export const ROW_REGION = {
    value: ResidenceType.REGION,
    order: 2
};
export const ROW_COUNTRY = {
    value: ResidenceType.COUNTRY,
    order: 1
};

export const DEFAULT_DISTRIBUTE_SCORE = 100;

export const Fillers: { [key: string]: FillerQuestion } = {
    [QuestionType.TEST_FEW_OF_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.TEST_FEW_OF_LIST,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: 0,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: { ...cloneDeep(initialQuestion.params), totalScore: true, testScore: 1 },
        conditions: []
    },
    [QuestionType.TEST_ONE_OF_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.TEST_ONE_OF_LIST,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: 0,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.TEST_TEXT]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.TEST_TEXT,
        rows: [1].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: 0,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: { ...cloneDeep(initialQuestion.params), testScore: 1 },
        conditions: []
    },
    [QuestionType.TEXT_BLOCK]: {
        ...cloneDeep(initialQuestion),
        name: TYPES_NAMES[QuestionType.TEXT_BLOCK],
        type: QuestionType.TEXT_BLOCK,
        rows: [],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            name: false,
            help: null,
            timer: null,
            comment: null,
            required: null
        },
        conditions: []
    },
    [QuestionType.AGREEMENT]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.AGREEMENT,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value: [BoolAnswerText.TRUE, BoolAnswerText.FALSE][id - 1]
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            name: false,
            timer: null,
            comment: null,
            agreementLeftLabel: 'Согласен с политикой обработки',
            agreementRightLabel: '',
            agreementLink: 'персональных данных',
            agreementText: ''
        },
        conditions: []
    },
    [QuestionType.PHONE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.PHONE,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value: ['моб.', 'дом.'][id - 1],
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            comment: null,
            phoneFormatCountry: '+7',
            phoneFormatNumber: '(495) 123-45-67'
        },
        conditions: []
    },
    [QuestionType.DATETIME]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.DATETIME,
        rows: [1].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            comment: null,
            dateFormat: DateFormat[0],
            dateType: DateTimeType.DATE_TIME,
            timeFormat: TimeFormat.TWENTY_FOUR
        },
        conditions: []
    },
    [QuestionType.EMAIL]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.EMAIL,
        rows: [1].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            comment: null
        },
        conditions: []
    },
    [QuestionType.NAME]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.NAME,
        rows: [1].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value: '',
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: ['Фамилия', 'Имя', 'Отчество'][id - 1],
            answers: []
        })),
        params: {
            ...cloneDeep(initialQuestion.params),
            comment: null
        },
        conditions: []
    },
    [QuestionType.RESIDENCE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.RESIDENCE,
        rows: [
            {
                ...cloneDeep(initialRow),
                ...ROW_COUNTRY
            }
        ],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            residence: ResidenceType.COUNTRY
        },
        conditions: []
    },
    [QuestionType.NESTED_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.NESTED_LIST,
        rows: [
            {
                ...cloneDeep(initialRow),
                value: 'Уровень 1',
                value3: 1,
                order: 1
            },
            {
                ...cloneDeep(initialRow),
                value: 'Уровень 2',
                value3: 2,
                order: 2
            },
            {
                ...cloneDeep(initialRow),
                value: 'Уровень 3',
                value3: 3,
                order: 3
            }
        ],
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.FILE_UPLOAD]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.FILE_UPLOAD,
        rows: [],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            fileAmount: 1,
            uploadButtonName: 'Загрузить файл',
            fileType: FileUploadType.ANY,
            fileLimitExt: []
        },
        conditions: []
    },
    [QuestionType.GROUP_FREE_ANSWERS]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.GROUP_FREE_ANSWERS,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.NUMBER]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.NUMBER,
        rows: [],
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.LONG_TEXT]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.LONG_TEXT,
        rows: [],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            comment: null
        },
        conditions: []
    },
    [QuestionType.SHORT_TEXT]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.SHORT_TEXT,
        rows: [],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            comment: null
        },
        conditions: []
    },
    [QuestionType.SEM_DIFF]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.SEM_DIFF,
        rows: [1].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3, 4, 5].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: `${id - 3}`,
            answers: [],
            type: AnswerType.RADIO
        })),
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.MATRIX_TEXT_ANSWER]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.MATRIX_TEXT_ANSWER,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3, 4, 5].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: '',
            answers: [],
            type: AnswerType.TEXT
        })),
        params: {
            ...cloneDeep(initialQuestion.params),
            answerType: 'any'
        },
        conditions: []
    },
    [QuestionType.MATRIX_FEW_ANSWERS]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.MATRIX_FEW_ANSWERS,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3, 4, 5].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: '',
            answers: [],
            type: AnswerType.CHECKBOX
        })),
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.MATRIX_SINGLE_ANSWER]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.MATRIX_SINGLE_ANSWER,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3, 4, 5].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: '',
            answers: [],
            type: AnswerType.RADIO
        })),
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.RANK]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.RANK,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: '',
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.TEST_RANK]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.TEST_RANK,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: '',
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: { ...cloneDeep(initialQuestion.params), randomOrder: true, testScore: 1 },
        conditions: []
    },
    [QuestionType.NPS]: {
        ...cloneDeep(initialQuestion),
        name: '&lt;p&gt;Пожалуйста, оцените по шкале от 0 до 10, насколько вероятно, что вы порекомендуете нас другу или коллеге?&lt;/p&gt;',
        type: QuestionType.NPS,
        rows: [],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            size: 1,
            npsLeftLabel: 'Маловероятно',
            npsRightLabel: 'Очень вероятно',
            npsColors: [getColor('blue-nps')]
        },
        conditions: []
    },
    [QuestionType.DISTRIBUTE_SCALE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.DISTRIBUTE_SCALE,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value: '',
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            distributeSurplus: true,
            distributeLabel: 'Распределить',
            distributeScore: DEFAULT_DISTRIBUTE_SCORE,
            distributeUnit: 'баллов'
        },
        conditions: []
    },
    [QuestionType.SMILE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.SMILE,
        rows: smileTypes[0].smiles.map((smile, key) => ({
            ...cloneDeep(initialRow),
            id: key,
            value: smile,
            order: key,
            fileId: null,
            filePath: null,
            width: 0,
            height: 0,
            mimeType: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            size: 1,
            smilePack: DEFAULT_SMILE_PACK,
            smileType: '5-smile'
        },
        conditions: []
    },
    [QuestionType.MATRIX_RATING]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.MATRIX_RATING,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            amount: 5,
            size: 1,
            color: getColor('blue-star')
        },
        conditions: []
    },
    [QuestionType.CSI]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.CSI,
        rows: [1].map((id) => {
            return {
                ...cloneDeep(initialRow),
                id,
                order: id,
                fileId: null,
                filePath: null,
                value2: 'Оценка',
                value3: 'Важность данного критерия для вас',
                commentText: 'Текст комментария'
            };
        }),
        cols: [1, 2].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: '',
            answers: [],
            type: AnswerType.TEXT
        })),
        params: {
            ...cloneDeep(initialQuestion.params),
            amount: 10,
            size: 1,
            color: getColor('blue-star'),
            matrixType: 'numbers'
        },
        conditions: []
    },
    [QuestionType.STAR]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.STAR,
        rows: [],
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            color: getColor('blue-star'),
            size: 2,
            amount: 5
        },
        conditions: []
    },
    [QuestionType.SCALE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.SCALE,
        rows: [1].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3, 4, 5].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            numericValue: id,
            name: String(id)
        })),
        params: {
            ...cloneDeep(initialQuestion.params),
            size: 1,
            npsLeftLabel: 'Плохо',
            npsRightLabel: 'Отлично'
        },
        conditions: []
    },
    [QuestionType.MATRIX_DROPDOWN_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.MATRIX_DROPDOWN_LIST,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [1, 2, 3, 4, 5].map((id) => ({
            ...cloneDeep(initialCol),
            id,
            order: id,
            name: '',
            type: AnswerType.SELECT,
            answers: []
        })),
        params: {
            ...cloneDeep(initialQuestion.params),
            dropdownListType: DropdownType.DIFFERENT,
            answers: []
        },
        conditions: []
    },
    [QuestionType.ONE_OF_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.ONE_OF_LIST,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: '',
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            randomOrder: false
        },
        conditions: []
    },
    [QuestionType.FEW_OF_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.FEW_OF_LIST,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value3: '',
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    },
    [QuestionType.SELECT_ONE_IMAGE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.SELECT_ONE_IMAGE,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            imageColumns: 1,
            size: 100,
            imageTextPosition: 'above'
        },
        conditions: []
    },
    [QuestionType.SELECT_FEW_IMAGE]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.SELECT_FEW_IMAGE,
        rows: [1, 2, 3].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: {
            ...cloneDeep(initialQuestion.params),
            imageColumns: 1,
            size: 100,
            imageTextPosition: 'above'
        },
        conditions: []
    },
    [QuestionType.DROPDOWN_LIST]: {
        ...cloneDeep(initialQuestion),
        type: QuestionType.DROPDOWN_LIST,
        rows: [1, 2].map((id) => ({
            ...cloneDeep(initialRow),
            id,
            order: id,
            value: '',
            value3: '',
            fileId: null,
            filePath: null
        })),
        cols: [],
        params: cloneDeep(initialQuestion.params),
        conditions: []
    }
};
