import { useCombobox } from 'downshift';
import type { UseComboboxReturnValue } from 'downshift';
import { useCallback } from 'react';

import { useDeepMemo } from '@webapp/common/hooks/use-deep-memo';

import { getLabel, getValue } from './lib';
import type { SelectOption } from './lib';

export const useDownshift = (
    options: Array<SelectOption>,
    selectedItems: Array<SelectOption>,
    setSelectedItems: (selectedItems: Array<SelectOption>) => void,
    updateSearch: (v: string) => void
): UseComboboxReturnValue<SelectOption> => {
    // TODO fix keyboard nav
    // highlightedIndex,

    const onInputValueChange = useCallback(
        ({ inputValue }) => updateSearch(inputValue === null ? '' : inputValue),
        [updateSearch]
    );

    const cb = useCombobox<SelectOption>({
        onInputValueChange,
        items: options,
        // there's no actual selection happening in the hook, we're doing it custom via selectedItems.
        selectedItem: null,
        itemToString: (o) => (o ? getLabel(o) : ''),
        onSelectedItemChange: ({ selectedItem }) => {
            if (!selectedItem) {
                return;
            }
            const index = selectedItems.map((i) => getValue(i)).indexOf(getValue(selectedItem));
            if (index > 0) {
                setSelectedItems([...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)]);
            } else if (index === 0) {
                setSelectedItems([...selectedItems.slice(1)]);
            } else {
                setSelectedItems([...selectedItems, selectedItem]);
            }
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, // keep menu open after selection.
                        highlightedIndex: state.highlightedIndex,
                        inputValue: '' // don't add the item string as input value at selection.
                    };
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        inputValue: '' // don't add the item string as input value at selection.
                    };
                default:
                    return changes;
            }
        }
    });

    return useDeepMemo(cb);
};
