export const getTrackColors = (colors: Array<string> | string) => {
    if (typeof colors === 'string') {
        return `${colors}, ${colors}`;
    }

    if (Array.isArray(colors)) {
        return (colors.length === 1 ? [colors[0], colors[0]] : colors).join(', ');
    }

    return colors;
};
